import {FC} from 'react';

export const WarningIcon: FC<{big?: boolean}> = ({big}) => {
  return big ? (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      style={{position: 'absolute', right: '14px', top: '38%'}}>
      <path
        d="M7.53027 9.3877H9.28027V4.4497H7.53027V9.3877ZM8.40527 12.7837C8.64127 12.7837 8.84627 12.7037 9.02027 12.5437C9.1936 12.3837 9.28027 12.1717 9.28027 11.9077C9.28027 11.6717 9.1936 11.467 9.02027 11.2937C8.84627 11.1204 8.64127 11.0337 8.40527 11.0337C8.16927 11.0337 7.96427 11.1204 7.79027 11.2937C7.61693 11.467 7.53027 11.6717 7.53027 11.9077C7.53027 12.1717 7.61693 12.3837 7.79027 12.5437C7.96427 12.7037 8.16927 12.7837 8.40527 12.7837ZM8.40527 16.9287C7.2526 16.9287 6.16927 16.71 5.15527 16.2727C4.14127 15.8354 3.25927 15.2417 2.50927 14.4917C1.75927 13.7417 1.1656 12.8597 0.728266 11.8457C0.290932 10.8317 0.0722656 9.74836 0.0722656 8.5957C0.0722656 7.44303 0.290932 6.3597 0.728266 5.3457C1.1656 4.3317 1.75927 3.4497 2.50927 2.6997C3.25927 1.9497 4.14127 1.35603 5.15527 0.918695C6.16927 0.481362 7.2526 0.262695 8.40527 0.262695C9.55793 0.262695 10.6413 0.481362 11.6553 0.918695C12.6693 1.35603 13.5513 1.9497 14.3013 2.6997C15.0513 3.4497 15.6449 4.3317 16.0823 5.3457C16.5196 6.3597 16.7383 7.44303 16.7383 8.5957C16.7383 9.74836 16.5196 10.8317 16.0823 11.8457C15.6449 12.8597 15.0513 13.7417 14.3013 14.4917C13.5513 15.2417 12.6693 15.8354 11.6553 16.2727C10.6413 16.71 9.55793 16.9287 8.40527 16.9287ZM8.40527 15.1787C10.2246 15.1787 11.7766 14.5364 13.0613 13.2517C14.3459 11.967 14.9883 10.415 14.9883 8.5957C14.9883 6.77636 14.3459 5.22436 13.0613 3.9397C11.7766 2.65503 10.2246 2.0127 8.40527 2.0127C6.58593 2.0127 5.03393 2.65503 3.74927 3.9397C2.4646 5.22436 1.82227 6.77636 1.82227 8.5957C1.82227 10.415 2.4646 11.967 3.74927 13.2517C5.03393 14.5364 6.58593 15.1787 8.40527 15.1787Z"
        fill="#E0A203"
      />
    </svg>
  ) : (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M8.12597 9.47949H9.87597V4.54149H8.12597V9.47949ZM9.00097 12.8755C9.23697 12.8755 9.44197 12.7955 9.61597 12.6355C9.7893 12.4755 9.87597 12.2635 9.87597 11.9995C9.87597 11.7635 9.7893 11.5588 9.61597 11.3855C9.44197 11.2122 9.23697 11.1255 9.00097 11.1255C8.76497 11.1255 8.55997 11.2122 8.38597 11.3855C8.21264 11.5588 8.12597 11.7635 8.12597 11.9995C8.12597 12.2635 8.21264 12.4755 8.38597 12.6355C8.55997 12.7955 8.76497 12.8755 9.00097 12.8755ZM9.00097 17.0205C7.8483 17.0205 6.76497 16.8018 5.75097 16.3645C4.73697 15.9272 3.85497 15.3335 3.10497 14.5835C2.35497 13.8335 1.7613 12.9515 1.32397 11.9375C0.886635 10.9235 0.667969 9.84016 0.667969 8.68749C0.667969 7.53483 0.886635 6.45149 1.32397 5.43749C1.7613 4.42349 2.35497 3.54149 3.10497 2.79149C3.85497 2.04149 4.73697 1.44783 5.75097 1.01049C6.76497 0.573159 7.8483 0.354492 9.00097 0.354492C10.1536 0.354492 11.237 0.573159 12.251 1.01049C13.265 1.44783 14.147 2.04149 14.897 2.79149C15.647 3.54149 16.2406 4.42349 16.678 5.43749C17.1153 6.45149 17.334 7.53483 17.334 8.68749C17.334 9.84016 17.1153 10.9235 16.678 11.9375C16.2406 12.9515 15.647 13.8335 14.897 14.5835C14.147 15.3335 13.265 15.9272 12.251 16.3645C11.237 16.8018 10.1536 17.0205 9.00097 17.0205ZM9.00097 15.2705C10.8203 15.2705 12.3723 14.6282 13.657 13.3435C14.9416 12.0588 15.584 10.5068 15.584 8.68749C15.584 6.86816 14.9416 5.31616 13.657 4.03149C12.3723 2.74683 10.8203 2.10449 9.00097 2.10449C7.18164 2.10449 5.62964 2.74683 4.34497 4.03149C3.0603 5.31616 2.41797 6.86816 2.41797 8.68749C2.41797 10.5068 3.0603 12.0588 4.34497 13.3435C5.62964 14.6282 7.18164 15.2705 9.00097 15.2705Z"
        fill="#E0A203"
      />
    </svg>
  );
};
